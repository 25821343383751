/** @format */

import React, { memo } from "react";
import { LeftWrapper } from "./style";
import IconLogo from "@/assets/svg/icon_logo";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeHeaderConfigAction } from "@/store/modules/main";

const HeaderLeft = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navHome = () => {
    dispatch(changeHeaderConfigAction({ isFixed: true, isHome: true }));
    navigate("/home");
  };

  return (
    <LeftWrapper>
      <div className="logo" onClick={navHome}>
        <IconLogo></IconLogo>
      </div>
    </LeftWrapper>
  );
});

export default HeaderLeft;
