/** @format */

import IconMoreArrow from "@/assets/svg/icon-more-arrow";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { FooterWrapper } from "./style";
import { useNavigate } from "react-router-dom";

const SectionFooter = memo((props) => {
  const navigate = useNavigate();
  const { cityName } = props;

  let showMessage = "显示全部";
  if (cityName) {
    showMessage = `查看更多${cityName}房源`;
  }

  const findHouseHandle = () => {
    navigate("/entire");
  };

  return (
    <FooterWrapper color={cityName ? "#00848A" : "#000"}>
      <div className="info" onClick={findHouseHandle}>
        <span className="text">{showMessage}</span>
        <IconMoreArrow />
      </div>
    </FooterWrapper>
  );
});

SectionFooter.propTypes = {
  cityName: PropTypes.string,
};

export default SectionFooter;
