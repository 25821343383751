import hyRequest from "..";

export function getHomeGoodPriceData() {
  return hyRequest.get({
    url: "/home/goodprice",
  });
}

export function getHomehighScoreData() {
  return hyRequest.get({
    url: "/home/highscore",
  });
}

export function getHomeDiscountData() {
  return hyRequest.get({
    url: "/home/discount",
  });
}

export function getHotrecommenddestData() {
  return hyRequest.get({
    url: "home/hotrecommenddest",
  });
}

export function getLongforData() {
  return hyRequest.get({
    url: "home/longfor",
  });
}

export function getplusData() {
  return hyRequest.get({
    url: "home/plus",
  });
}
