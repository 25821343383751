/** @format */
import { memo, useEffect } from "react";
import axios from "axios";
import { Modal } from "antd"; // 确保你已经安装并引入了 Modal 组件

const VersionCherker = memo((props) => {
  useEffect(() => {
    // 浏览器刷新则直接自动更换版本不会弹出提示刷新
    window.addEventListener("load", () => {
      let url = `//${window.location.host}/version.json?v=${Math.random()}`;
      if (process.env.NODE_ENV === "production") {
        axios.get(url).then((res) => {
          let lastVersion = res.data.version;
          localStorage.setItem("version", lastVersion);
          localStorage.removeItem("loadVersion");
        });
      }
    });

    return () => {
      window.removeEventListener("load", () => {});
    };
  }, []);

  const getVersion = async () => {
    let url = `http://${window.location.host}/version.json?v=${Math.random()}`;
    if (process.env.NODE_ENV === "production" && !localStorage.loadVersion) {
      axios.get(url).then((res) => {
        let lastVersion = res.data.version;
        // 如果浏览器本地存储没有version则存储一个不弹窗
        if (localStorage.version === undefined) {
          localStorage.setItem("version", lastVersion);
        } else if (localStorage.version != lastVersion) {
          // 如果有version，获取到的进行对比不同则弹窗并在本地存储一个loadVersion（用作判断是否刷新过）
          localStorage.setItem("loadVersion", "true");
          Modal.confirm({
            title: "版本升级提示",
            content: "监测到新版本是否刷新？",
            width: 400,
            okText: "确认刷新",
            cancelText: "稍后刷新",
            onOk: async () => {
              // 直接刷新，存储新的version 本地存储的loadVersion移除下次进入判断的时候只有version值不同才会弹窗提示
              window.location.reload();
              localStorage.setItem("version", lastVersion);
              localStorage.removeItem("loadVersion");
            },
            onCancel: async () => {
              // 取消那么则移出loadVersion，下次定时触发的时候！localStorage.loadVersion才会为true
              // 再次进入判断直到点击确定刷新
              localStorage.removeItem("loadVersion");
            },
          });
        }
      });
    }
  };

  setInterval(() => {
    getVersion();
  }, 300000);

  return null; // This component does not render anything
});

export default VersionCherker;
