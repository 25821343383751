/** @format */

import React from "react";
import { Navigate } from "react-router-dom";

// const Home = React.lazy(() => import("@/views/home")); //1.异步实现懒加载组件会加载两次，但是可以在打包生成单独的js包，Webpack 自动代码分割的异步组件
import Home from "@/views/home"; //2.同一，则反，不会加载两次
const Detail = React.lazy(() => import("@/views/detail"));
const Entire = React.lazy(() => import("@/views/entire"));

const router = [
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "home",
    element: <Home />,
  },
  {
    path: "detail",
    element: <Detail />,
  },
  {
    path: "entire",
    element: <Entire />,
  },
];

export default router;
