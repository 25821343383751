/** @format */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * 回到顶部
 */
export default function useScrollTop() {
  const { pathname } = useLocation;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
