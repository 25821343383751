import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getHomeGoodPriceData,
  getHomehighScoreData,
  getHomeDiscountData,
  getHotrecommenddestData,
  getLongforData,
  getplusData,
} from "@/services";

/**
 createAsyncThunk使用async await发起异步请求,但是对于多个请求如果想写在一个action不太友好，
 只能等前面的awati请求成功才能走下面的请求，所以多个请求写在同一个createAsyncThunk函数中，用priomise的比较适合

export const fetchHomeDataAction = createAsyncThunk("fetchData", async () => {
  const res = await getHomeGoodPriceData();
  return res;
}); 
*/
export const fetchHomeDataAction = createAsyncThunk(
  "fetchData",
  (payload, { dispatch }) => {
    getHomeGoodPriceData().then((res) => {
      dispatch(changeGoodPriceInfoAction(res));
    });
    getHomehighScoreData().then((res) => {
      dispatch(changeHighScoreInfoAction(res));
    });
    getHomeDiscountData().then((res) => {
      dispatch(changeDiscountInfoAction(res));
    });
    getHotrecommenddestData().then((res) => {
      dispatch(changeHotrecommendInfoAction(res));
    });
    getLongforData().then((res) => {
      dispatch(changeLongforAction(res));
    });
    getplusData().then((res) => {
      dispatch(changePlusAction(res));
    });
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: {
    goodPriceInfo: {},
    highScoreInfo: {},
    discountInfo: {},
    hotrecommendInfo: {},
    longforInfo: {},
    plusInfo: {},
  },
  reducers: {
    changeGoodPriceInfoAction(state, { payload }) {
      state.goodPriceInfo = payload;
    },
    changeHighScoreInfoAction(state, { payload }) {
      state.highScoreInfo = payload;
    },
    changeDiscountInfoAction(state, { payload }) {
      state.discountInfo = payload;
    },
    changeHotrecommendInfoAction(state, { payload }) {
      state.hotrecommendInfo = payload;
    },
    changeLongforAction(state, { payload }) {
      state.longforInfo = payload;
    },
    changePlusAction(state, { payload }) {
      state.plusInfo = payload;
    },
  },
  /**
  // extraReducers: { //此对象写法已被弃用，改用生成器回调表示法
  //   [fetchHomeDataAction.fulfilled](state, { payload }) {
  //     state.goodPriceInfo = payload;
  //   },
  // },
  //这种格式一般配合createAsyncThunk异步请求为async await使用
  extraReducers: (builder) => {
    builder.addCase(fetchHomeDataAction.fulfilled, (state, { payload }) => {
      state.goodPriceInfo = payload;
    });
  },
   */
});

export const {
  changeGoodPriceInfoAction,
  changeHighScoreInfoAction,
  changeDiscountInfoAction,
  changeHotrecommendInfoAction,
  changeLongforAction,
  changePlusAction,
} = homeSlice.actions;

export default homeSlice.reducer;
