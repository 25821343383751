/** @format */

import React, { memo, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./router";
import AppHeader from "components/app-header";
import AppFooter from "components/app-footer";
import { useScrollTop } from "./hooks";
import VersionCherker from "./components/version-checker";

const App = memo(() => {
  useScrollTop();

  return (
    <div className="app">
      {/* 线上版本更新提示组件 */}
      <VersionCherker />
      <AppHeader />
      <Suspense callbak="loading">
        <div className="page">{useRoutes(routes)}</div>
      </Suspense>
      <AppFooter />
    </div>
  );
});

export default App;
